import React from "react";

import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import { Frame, Heading, Row } from "arwes";

import Loading from '../components/loading';

class Projects extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<Loading />);
        }
        
        return (<Layout>
            <div style={{ maxWidth: "400px" }}>
                <Frame animate layer='primary' corners={4}>
                    <div style={{paddingTop: "10px"}}>
                        <Heading node='h1'>Projects</Heading>
                    </div>
                </Frame>
            </div>

            <div style={{ padding: "20px" }}>
                <Row>
                    <ProjectCard 
                        header='VPN' 
                        content="Built a Wireguard VPN for use while traveling abroad. It is around 2x faster, with less attack surface than an equivalent OpenVPN server. The server is hosted with 2 redundant backups in 2 different countries on AWS EC2 instances, and it hasn't leaked a DNS query since it was brought online. In order to evade firewalls, it routes traffic through port 80, and it hasn't been down in the past year."
                    />
                    <ProjectCard 
                        header='Charles The Robot' 
                        content="Equipped with GPS, Wifi, ultrasonic sensors, and up to 2 hours of battery life while exploring, Charles The Robot is my newest pet project. His brain is implemented in Python and allows him to autonomously explore rooms in order to map out the space. He was intended to be small, but instead this little robot turned out to be 24 inches by 24 inches by 12 inches, designed entirely in Autodesk's Fusion 360."
                    />
                </Row>
                <Row>
                    <ProjectCard 
                        header='Home DNS+Adblock' 
                        content="Blocks ads from major sources (Instagram, Snapchat, etc.) for all devices on my home network. There's also a backup DNS server that's only been used 3 times in the past year. Since the original implementation, I've switched this particular project to a Raspberry Pi and Pihole. "
                    />
                    <ProjectCard 
                        header='42eof LLC' 
                        content="My personal company, through which I do the occasional cybersecurity consulting work, sell a VPN service, and produce and sell a handful of cybersecurity-oriented products. It's mainly an endeavor in starting my own company, but it's been a blast to build throughout the process."
                    />
                </Row>
            </div>
        </Layout>);
    }
}

export default Projects