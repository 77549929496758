import React from 'react';

import { Project, Words, Col } from 'arwes';

class ProjectCard extends React.Component {
    render() {
        return (
            <Col s={12} m={12} l={6} style={{ padding: "20px" }}>
                <Project animate header={this.props.header}>
                    {anim => (
                        <p>
                            <Words animate show={anim.entered}>
                                {this.props.content}
                            </Words>
                        </p>
                    )}
                </Project>
            </Col>
        )
    }
}

export default ProjectCard;